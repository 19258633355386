<template>
  <div
    class="form-no-messages"
    style="position:relative"
  >
    <v-container class="pt-0">
      <v-row class="ma-0 pa-0">
        <v-col
          class="pa-0"
          cols="6"
        >
          <v-dialog
            ref="dialog"
            v-model="modal"
            class="date-picker"
            :return-value.sync="receiptDate"
            :width="datePickerWidth"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="receiptDate"
                style="width:180px;"
                label="처리일"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                autocomplete="off"
                v-on="on"
              />
            </template>
            <v-date-picker
              v-model="receiptDate"
              locale="ko-ko"
              @input="onDateInput"
            />
          </v-dialog>
        </v-col>
        <!--
        <v-col
          class="pa-0"
          cols="6"
        >
          <v-select
            v-model="selectedInType"
            :items="inTypes"
            item-text="inTypeText"
            item-value="inTypeVal"
            menu-props="auto"
            label="ㅇㄴㅁㅇㅁㄴ"
            prepend-icon="mdi-map"
            persistent-hint
            return-object
            single-line
            @input="onDateInput"
          />
        </v-col>
        -->
      </v-row>
      <div class="data-grid">
        <dx-data-grid
          ref="refDailyReceiptrGrid"
          :data-source="receiptInfoList"
          :show-borders="true"
          :show-row-lines="false"
          :show-column-lines="true"
          :hover-state-enabled="true"
          :allow-column-resizing="true"
          column-resizing-mode="widget"
          :allow-column-reordering="true"
          :height="gridHeight"
          :row-alternation-enabled="true"
          :focused-row-enabled="true"
          :auto-navigate-to-focused-row="false"
          key-expr="seqId"
          @cell-prepared="onCellPreparedOutput"
        >
          <DxColumn
            :allow-editing="true"
            caption="NO"
            data-field="입고키"
            :min-width="50"
          />
          <DxColumn
            :allow-editing="true"
            caption="품번"
            data-field="품번"
            :min-width="140"
          />
          <DxColumn
            :allow-editing="true"
            caption="품명"
            data-field="품명"
            :visible="true"
            :min-width="140"
          />
          <DxColumn
            :allow-editing="true"
            caption="수량"
            data-field="수량"
            data-type="number"
            :visible="true"
            format="#,##0"
            :min-width="80"
          />
          <DxColumn
            :allow-editing="true"
            caption="구분"
            data-field="입출고구분"
            :visible="true"
            :min-width="80"
          >
            <DxLookup
              :data-source="receiptTypeLookup"
              display-expr="코드명"
              value-expr="코드"
            />
          </DxColumn>
          <DxSummary>
            <DxTotalItem
              value-format="#,##0"
              column="품번"
              display-format="{0} (건)"
            />
          </DxSummary>
          <dx-paging :enabled="false" />
          <dx-scrolling
            mode="virtual"
            show-scrollbar="always"
            :use-native="true"
          />
          <dx-sorting mode="multiple" />
        </dx-data-grid>
      </div>
      <loading
        :active.sync="isLoading"
        color="rgb(2, 110, 156)"
        loader="bars"
      />
    </v-container>
  </div>
</template>
<script>
import Loading from 'vue-loading-overlay'
import AppLib from '../../share/AppLib'
import ConstDef from '../../../../BnhMesVueWin/src/share/ConstDef'
// import HttpService from '../../share/service/HttpService'
// import AesCrypto from '../../share/service/AesCrypto'
import DxDataGrid, {
  DxScrolling, DxTotalItem, DxLookup,
  DxSummary, DxColumn, DxPaging, DxSorting
} from 'devextreme-vue/data-grid'

export default {
  name: 'WorkOrder',
  components: {
    Loading,
    DxDataGrid,
    DxScrolling,
    DxTotalItem,
    DxLookup,
    DxSummary,
    DxColumn,
    DxPaging,
    DxSorting
  },
  data: function () {
    return {
      isLoading: false,
      userInfo: null,
      receiptInfoList: null,
      gridHeight: null,
      receiptDate: AppLib.getToday(),
      goodsLookup: null,
      modal: false,
      datePickerWidth: '350px',
      receiptTypeLookup: null,
      columnWidthOrg: [140, 100, 100, 60, 100, 100],
      columnWidth: []
    }
  },
  created () {
    this.userInfo = this.$store.getters.getUserInfo
    if (this.userInfo === undefined || this.userInfo === null) {
      this.$router.push('/')
      return
    }
    this.receiptTypeLookup = ConstDef.GOODS_INOUT_CODE
    console.log(this.receiptTypeLookup)
    this.datePickerWidth = this.getDatePickerWidth()
    this.receiptDate = AppLib.getToday()
    this.goodsLookup = this.$store.getters.getBaseDataByType(ConstDef.품목유형)
    this.refreshInventoryList()
  },
  mounted () {
    window.addEventListener('resize', this.handleResize)
    this.handleResize()
  },
  beforeDestroy: function () {
    window.removeEventListener('resize', this.handleResize)
  },
  updated () {
  },
  methods: {
    handleResize () {
      this.gridHeight = (this.$Q('html').height() - 90) - 30
      if (this.$store.getters.getMobileModel === 'TABLET' || !AppLib.isScreenVertical(this.$Q)) {
        let w = this.$Q('.data-grid').width() - 2
        w = ((w - this.columnWidthOrg[0]) / (this.columnWidthOrg.length - 1))
        const len = this.columnWidthOrg.length
        this.columnWidth = []
        for (let i = 0; i < len; i++) {
          if (i === 0) {
            this.columnWidth.push(this.columnWidthOrg[0])
          } else {
            this.columnWidth.push(w)
          }
        }
      } else {
        this.columnWidth = this.columnWidthOrg
      }
    },
    getDatePickerWidth () {
      return `${AppLib.getDatePickerWidth(this.$store)}px`
    },
    onCellPreparedOutput (e) {
      if (e.rowType === 'data' && e.column.dataField === '입출고구분') {
        if (e.data.입출고구분 === this.$_.findWhere(ConstDef.PRODUCTION_INOUT_CODE, { 코드명: '제품반품' }).코드) { // 재공재고
          this.$Q(e.cellElement).css('color', 'red')
        }
      }
    },
    onDateInput (e) {
      this.$refs.dialog.save(this.receiptDate)
      this.refreshInventoryList()
    },
    refreshInventoryList () {
      this.isLoading = true
      const param = {
        startDate: this.receiptDate,
        endDate: `${this.receiptDate} 23:59:59`
      }
      this.$_sp.runNoEncodeFindProc('spFindAllInventoryReceiptByDate', param)
        .then((data) => {
          this.isLoading = false
          this.receiptInfoList = this.$_sp.MakeModel(data)
          console.log(this.receiptInfoList)
        })
        .catch(error => {
          this.isLoading = false
          if (error.response.status === ConstDef.GENERRAL_ERROR) {
            this.$snotify.error(error.response.data.message)
          } else {
            this.$snotify.error(`에러코드 : ${error.response.status}`)
          }
        })
    }
  }
}
</script>

<style lang="scss">
  .dx-toolbar-before {
    width: 300px!important;
  }
</style>
